@media (min-width: 1025px) {
  .kt-aside__brand {
    background-color: #1a1a27;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .kt-aside__brand
    .kt-aside__brand-tools
    .kt-aside__brand-aside-toggler
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #494b74;
  }
  .kt-aside__brand
    .kt-aside__brand-tools
    .kt-aside__brand-aside-toggler
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .kt-aside__brand
    .kt-aside__brand-tools
    .kt-aside__brand-aside-toggler:hover
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #5d78ff;
  }
  .kt-aside__brand
    .kt-aside__brand-tools
    .kt-aside__brand-aside-toggler:hover
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
}

@media (max-width: 1024px) {
  .kt-header-mobile {
    background-color: #1a1a27;
    -webkit-box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.1);
  }
  .kt-header__topbar--mobile-on .kt-header-mobile {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .kt-header-mobile .kt-header-mobile__toolbar .kt-header-mobile__toggler span {
    background: #484f66;
  }
  .kt-header-mobile
    .kt-header-mobile__toolbar
    .kt-header-mobile__toggler
    span::before,
  .kt-header-mobile
    .kt-header-mobile__toolbar
    .kt-header-mobile__toggler
    span::after {
    background: #484f66;
  }
  .kt-header-mobile
    .kt-header-mobile__toolbar
    .kt-header-mobile__toggler:hover
    span {
    background: #ffffff;
  }
  .kt-header-mobile
    .kt-header-mobile__toolbar
    .kt-header-mobile__toggler:hover
    span::before,
  .kt-header-mobile
    .kt-header-mobile__toolbar
    .kt-header-mobile__toggler:hover
    span::after {
    background: #ffffff;
  }
  .kt-header-mobile
    .kt-header-mobile__toolbar
    .kt-header-mobile__toggler.kt-header-mobile__toggler--active
    span {
    background: #ffffff;
  }
  .kt-header-mobile
    .kt-header-mobile__toolbar
    .kt-header-mobile__toggler.kt-header-mobile__toggler--active
    span::before,
  .kt-header-mobile
    .kt-header-mobile__toolbar
    .kt-header-mobile__toggler.kt-header-mobile__toggler--active
    span::after {
    background: #ffffff;
  }
  .kt-header-mobile
    .kt-header-mobile__toolbar
    .kt-header-mobile__topbar-toggler
    i {
    color: #484f66;
  }
  .kt-header-mobile
    .kt-header-mobile__toolbar
    .kt-header-mobile__topbar-toggler.kt-header-mobile__topbar-toggler--active
    i,
  .kt-header-mobile
    .kt-header-mobile__toolbar
    .kt-header-mobile__topbar-toggler:hover
    i {
    color: #ffffff;
  }
}
