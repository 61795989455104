/* You can add global styles to this file, and also import other style files */
@import "assets/vendors/global/vendors.bundle.css";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "./main.scss";
@import "@ng-select/ng-select/themes/default.theme.css";

@import "../node_modules/bootstrap/scss/bootstrap.scss";

/* Grid container */
.grid-row {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: 16px;
  align-items: baseline;

  .grid-col-1 {
    grid-column: span 1;
  }

  .grid-col-2 {
    grid-column: span 2;
  }

  .grid-col-3 {
    grid-column: span 3;
  }

  .grid-col-4 {
    grid-column: span 4;
  }

  .grid-col-5 {
    grid-column: span 5;
  }

  .grid-col-6 {
    grid-column: span 6;
  }

  .grid-col-7 {
    grid-column: span 7;
  }

  .grid-col-8 {
    grid-column: span 8;
  }

  .grid-col-9 {
    grid-column: span 9;
  }

  .grid-col-10 {
    grid-column: span 10;
  }

  .grid-col-11 {
    grid-column: span 11;
  }

  .grid-col-12 {
    grid-column: span 12;
  }

  @media (max-width: 768px) {
    [class*="grid-col-"] {
      grid-column: span 12;
    }
  }
}

.rvp-preloader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.rvp-preloader div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #8f8e8e;
  animation: rvp-preloader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.rvp-preloader div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.rvp-preloader div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.rvp-preloader div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes rvp-preloader {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}


* {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

.mat-tab-body-wrapper,
// angular < 16
.mat-mdc-tab-body-wrapper // angular >= 16

  {
  flex-grow: 1;
}

.formly-field-select {
  .ng-select-container {
    height: calc(1.5em + 1.3rem + 1px) !important;
    border: 1px solid #e2e5ec !important;
  }
}

// hide bootstrap dropdown arrow globally
.dropdown-toggle::after {
  display: none !important;
}

.mat-mdc-table__wrapper--dashboard .mat-table {
  min-width: 600px !important;
  width: 100% !important;
}

.mat-mdc-table__wrapper {
  &--dashboard .mat-mdc-table {
    min-width: 600px !important;
    width: 100% !important;
  }

  &--scanning-log {
    min-width: 2000px !important;
    width: 100% !important;
  }
}

.position-static {
  position: static;
}

.cdk-global-scrollblock {
  overflow: auto !important;
  position: absolute !important;
}

.hidden {
  display: none;
}

.example-header-image1 {
  height: unset !important;
  width: unset !important;
  margin: 0 auto;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
.mat-card-header-text {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.div-daterange {
  width: 250px !important;
}

canvas.drawing,
canvas.drawingBuffer {
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
}

.min-width-600 {
  min-width: 600px !important;
}

.min-width-700 {
  min-width: 700px !important;
}

.min-width-500 {
  min-width: 500px !important;
}

.min-width-400 {
  min-width: 400px !important;
}

#inputBarcode {
  width: 100%;

  canvas {
    max-width: 100%;
    width: 100%;
  }

  video {
    max-width: 100%;
    width: 100%;
  }
}

.kt-product-icon {
  font-size: 22px;
  color: #4292e3;
}

.btn-underline {
  text-decoration: underline !important;
}

.color-red {
  color: red;
}

.color-green {
  color: green;
}

.color-skyblue {
  color: #4292e3 !important;
}

.order-split-view {
  .mat-mdc-dialog-container {
    overflow: hidden !important;
    padding: 24px 10px;
  }

  .mat-mdc-dialog-content {
    max-height: unset;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(100, 108, 154);
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(140, 147, 190);
}

.kt_wrapper {
  padding-top: 65px !important
}

.kt-portlet {
  height: 100%;
  margin-bottom: 0px !important
}
