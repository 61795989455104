@media (min-width: 1025px) {
  .kt-header-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link {
    background-color: none;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span {
    background-color: #9096b8;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #9096b8;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-text {
    color: #9096b8;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__hor-arrow {
    color: #6c7293;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link {
    background-color: none;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span {
    background-color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-text {
    color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    > .kt-menu__hor-arrow {
    color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link {
    background-color: none;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span {
    background-color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-text {
    color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    > .kt-menu__hor-arrow {
    color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link {
    background-color: none;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill],
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill],
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span {
    background-color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-text,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-text {
    color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    > .kt-menu__hor-arrow,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    > .kt-menu__hor-arrow {
    color: #5d78ff;
  }
  .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu {
    background-color: #ffffff;
    -webkit-box-shadow: 0px 15px 50px 0px rgba(82, 63, 105, 0.15);
    box-shadow: 0px 15px 50px 0px rgba(82, 63, 105, 0.15);
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-icon {
    color: #cacad2;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #cacad2;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span {
    background-color: #cacad2;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #cacad2;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-text {
    color: #5f6281;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__hor-arrow {
    color: #cacad2;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link {
    background-color: #fafbff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-icon {
    color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span {
    background-color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-text {
    color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    > .kt-menu__hor-arrow {
    color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link {
    background-color: #fafbff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-icon {
    color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span {
    background-color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-text {
    color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    > .kt-menu__hor-arrow {
    color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link {
    background-color: #fafbff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-icon,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-icon {
    color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill],
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill],
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span {
    background-color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-text,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-text {
    color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    > .kt-menu__hor-arrow,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    > .kt-menu__hor-arrow {
    color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    > .kt-menu__item {
    border-right: 1px solid #f6f6f9;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__heading
    > .kt-menu__link-text {
    color: #5f6281;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__heading
    > .kt-menu__link-icon {
    color: #cacad2;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-icon {
    color: #cacad2;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #cacad2;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span {
    background-color: #cacad2;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #cacad2;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__link-text {
    color: #5f6281;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item
    > .kt-menu__link
    .kt-menu__hor-arrow {
    color: #cacad2;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link {
    background-color: #fafbff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-icon {
    color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span {
    background-color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    .kt-menu__link-text {
    color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--active
    > .kt-menu__link
    > .kt-menu__hor-arrow {
    color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link {
    background-color: #fafbff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-icon {
    color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span {
    background-color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    .kt-menu__link-text {
    color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--here
    > .kt-menu__link
    > .kt-menu__hor-arrow {
    color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link {
    background-color: #fafbff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-icon,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-icon {
    color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill],
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-icon
    svg
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill],
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-icon
    svg:hover
    g
    [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--dot
    > span {
    background-color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-bullet.kt-menu__link-bullet--line
    > span {
    background-color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-text,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    .kt-menu__link-text {
    color: #5d78ff;
  }
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    > .kt-menu__hor-arrow,
  .kt-header-menu
    .kt-menu__nav
    > .kt-menu__item
    .kt-menu__submenu
    > .kt-menu__subnav
    .kt-menu__content
    .kt-menu__inner
    > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)
    > .kt-menu__link
    > .kt-menu__hor-arrow {
    color: #5d78ff;
  }
}
